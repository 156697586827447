<template>
  
    <v-card min-height="500" class="news-card">
      <div class="img-section container-img">
        <img
          class="card-img img-generic-100"
          :src="baseUrl + imgUrl"
        />
      </div>
      <div class="card-section">
        <v-card-text class="py-2 news-date">{{ dataPubblicazione | newsDate }}</v-card-text>
        <v-card-text class="news-title py-0" v-html="titolo"></v-card-text>
        <v-card-text
          class="news-description"
          v-html="displayRiassunto"
        ></v-card-text>
      </div>
      <router-link :to="route"><span class="sr-only">Per saperne di pi&ugrave; su {{titolo}}</span>  </router-link>
    </v-card>

</template>

<script>
import { NEWS_DETAIL } from "../router/routes";
export default {
  name: "NewsCard",
  props: {
    news: { type: Object, required: false },
  },
  data() {
    return {
      baseUrl: process.env.VUE_APP_API_BASE_URL,
    };
  },
  computed: {
    id() {
      return this.news?.id ?? "";
    },
    route() {
      return { name: NEWS_DETAIL.name, params: { id: this.id } };
    },
    titolo() {
      return this.news?.titolo ?? "";
    },
    dataPubblicazione() {
      return this.news?.data_pubblicazione ?? "";
    },
    imgUrl() {
      return this.news?.url_immagine ?? "";
    },
    riassunto() {
      return this.news?.riassunto ?? "";
    },
    displayRiassunto() {
      return this.riassunto.length < 210
        ? this.riassunto
        : this.riassunto.substring(0, 200) + "...";
    },
  },
};
</script>

<style lang="scss">
@import "../styles/variables.scss";
.img-section {
  //height: 180px;
}
.news-date {
  font-weight: bold;
}
.news-title {
  font: normal normal 600 21px/30px Open Sans;
  letter-spacing: 0px;
  word-break: break-word;
}
.news-description {
  font: normal normal normal 16px/24px Open Sans;
  letter-spacing: 0px;
  a {
    text-decoration: none;
    color: $text-dark;
  }
}
.news-card{

  position:relative;

  a{

    position: absolute;
    width:100%;
    height:100%;
    display: block;
    top:0;
  }
}
</style>