<template>
  <div class="news">
    <!-- page title -->
    <page-title></page-title>

    <!-- news body -->
    <v-container :fluid="$vuetify.breakpoint.mdAndDown">
      <v-row>
        <v-col
          class="col-12 pb-16"
          :class="index == 0 ? 'col-md-12' : 'col-md-4'"
          v-for="(news, index) in displayedNews"
          :key="news.titolo"
        >
          <!-- if first news -->
          <v-row
            class="first-news"
            v-if="index == 0"
            @click="goToNews(news.id)"
          >
            <v-col class="col-12 col-md-6">
              <div class="container-img one-news">
              <img
                :src="baseUrl + news.url_immagine"
                alt=" "
                class="img-generic-100"
              />
              </div>
            </v-col>
            <v-col class="col-12 col-md-6">
              <p class="first-date">{{ news.data_pubblicazione | newsDate }}</p>
              <p class="first-title" v-html="news.titolo"></p>
              <p class="first-description mt-8" v-html="news.riassunto"></p>
            </v-col>
          </v-row>
          <news-card v-else :news="news"></news-card>
        </v-col>
      </v-row>

      <!-- show all btn -->
      <v-row class="justify-center py-16">
        <v-btn
          class="show-all-btn"
          v-if="newsList.length > shownNumber"
          @click="showMore"
          color="#2AB5B9"
          >carica altre</v-btn
        >
      </v-row>
    </v-container>
  </div>
</template>

<script>
import PageTitle from "../components/PageTitle";
import NewsCard from "../components/NewsCard";
import { getNews } from "../services/api";
import { NEWS_DETAIL } from "../router/routes";
export default {
  name: "News",
  components: {
    PageTitle,
    NewsCard,
  },
  data() {
    return {
      baseUrl: process.env.VUE_APP_API_BASE_URL,
      shownNumber: 10,
    };
  },
  async created() {
    this.$emit("setLoading", true);

    let newsPromise = getNews();

    if (this.$store.getters["getNewsList"].length == 0) {
      try {
        let { data } = await newsPromise;

        this.$store.dispatch("setNewsList", {
          newsList: data,
        });
      } catch (err) {
        console.log(err);
      }
    }

    this.$emit("setLoading", false);
  },
  computed: {
    newsList() {
      return this.$store.getters["getNewsList"] ?? [];
    },
    displayedNews() {
      return this.newsList.slice(0, this.shownNumber);
    },
  },
  methods: {
    goToNews(id) {
      let name = NEWS_DETAIL.name;
      let params = { id: id };
      this.$router.push({ name, params });
    },
    showMore() {
      this.shownNumber += 3;
    },
  },
};
</script>

<style  lang="scss">
@import "@/styles/variables.scss";
.container-img.one-news{
  height:350px!important;
}
.news {
  a {
    text-decoration: none;
    color: $text-dark;
  }
  .first-news {
    cursor: pointer;
    .first-date {
      font: normal normal bold 23px/23px Open Sans;
      letter-spacing: 0px;
      color: $text-dark;
    }
    .first-title {
      font: normal normal bold 44px/60px Open Sans;
      letter-spacing: 0px;
      color: $text-dark;
    }
    .first-description {
      font: normal normal normal 18px/27px Open Sans;
      letter-spacing: 0px;
      color: $text-dark;
    }
  }
}
</style>